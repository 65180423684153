import { Builder } from '@builder.io/react';
import {
  Accordion,
  AccordionConfig,
  AppleActions,
  AppleActionsConfig,
  AppleLoginUnlock,
  AppleLoginUnlockConfig,
  AppleStream,
  AppleStreamConfig,
  AppleStreamingUnlock,
  AppleStreamingUnlockConfig,
  ArtistCollections,
  ArtistCollectionsConfig,
  Audio,
  AudioConfig,
  Button,
  ButtonConfig,
  CountDown,
  CountDownConfig,
  DataCapture,
  DataCaptureConfig,
  DeezerActions,
  DeezerActionsConfig,
  Document,
  DocumentConfig,
  EmailSignup,
  EmailSignupConfig,
  Embed,
  EmbedConfig,
  ExternalButton,
  ExternalButtonConfig,
  GeneratedImage,
  GeneratedImageConfig,
  ImageConfig,
  InstagramEmbed,
  InstagramEmbedConfig,
  ImageGatsby,
  Navbar,
  NavbarConfig,
  Juicer,
  JuicerConfig,
  Klaviyo,
  KlaviyoConfig,
  LegacyVideo,
  LegacyVideoWidgetConfig,
  Legal,
  LegalConfig,
  PhotoPlaygroundEditor,
  PhotoPlaygroundEditorConfig,
  Poll,
  PollConfig,
  PromoConfig,
  PromotionsWidget,
  QuickfireQuiz,
  QuickfireQuizConfig,
  ShareOnSocialConfig,
  SignupFormWidget,
  SignupFormConfig,
  ShopifyEmbed,
  ShopifyEmbedConfig,
  SocialFollow,
  SocialFollowConfig,
  SocialShare,
  Songkick,
  SongkickConfig,
  SpotifyActions,
  SpotifyActionsConfig,
  SpotifyPlaylistCreator,
  SpotifyPlaylistCreatorConfig,
  SpotifyLoginUnlock,
  SpotifyLoginUnlockConfig,
  SpotifyStream,
  SpotifyStreamConfig,
  SpotifyStreamingUnlock,
  SpotifyStreamingUnlockConfig,
  Stackla,
  StacklaConfig,
  Text,
  TextConfig,
  TextRich,
  TextRichConfig,
  TikTok,
  TikTokConfig,
  TourDates,
  TourDatesConfig,
  TranslationConfig,
  Translations,
  Twitch,
  TwitchConfig,
  Video,
  VideoConfig,
  ForeverPresaves,
  ForeverPresavesConfig
} from '@umg/mira-widgets';
import { setConfig } from '@umg/mira-widgets/lib/config';
import { config } from '../config';

setConfig(config);

Builder.registerComponent(Accordion, AccordionConfig);
Builder.registerComponent(AppleActions, AppleActionsConfig);
Builder.registerComponent(AppleLoginUnlock, AppleLoginUnlockConfig);
Builder.registerComponent(AppleStream, AppleStreamConfig);
Builder.registerComponent(AppleStreamingUnlock, AppleStreamingUnlockConfig);
Builder.registerComponent(ArtistCollections, ArtistCollectionsConfig);
Builder.registerComponent(Audio, AudioConfig);
Builder.registerComponent(Button, ButtonConfig);
Builder.registerComponent(CountDown, CountDownConfig);
Builder.registerComponent(DataCapture, DataCaptureConfig);
Builder.registerComponent(DeezerActions, DeezerActionsConfig);
Builder.registerComponent(Document, DocumentConfig);
Builder.registerComponent(EmailSignup, EmailSignupConfig);
Builder.registerComponent(Embed, EmbedConfig);
Builder.registerComponent(ExternalButton, ExternalButtonConfig);
Builder.registerComponent(GeneratedImage, GeneratedImageConfig);
Builder.registerComponent(ImageGatsby, ImageConfig);
Builder.registerComponent(InstagramEmbed, InstagramEmbedConfig);
Builder.registerComponent(Juicer, JuicerConfig);
Builder.registerComponent(Klaviyo, KlaviyoConfig);
Builder.registerComponent(Navbar, NavbarConfig);
Builder.registerComponent(LegacyVideo, LegacyVideoWidgetConfig);
Builder.registerComponent(Legal, LegalConfig);
Builder.registerComponent(PhotoPlaygroundEditor, PhotoPlaygroundEditorConfig);
Builder.registerComponent(Poll, PollConfig);
Builder.registerComponent(PromotionsWidget, PromoConfig);
Builder.registerComponent(QuickfireQuiz, QuickfireQuizConfig);
Builder.registerComponent(ShopifyEmbed, ShopifyEmbedConfig);
Builder.registerComponent(SignupFormWidget, SignupFormConfig);
Builder.registerComponent(SocialFollow, SocialFollowConfig);
Builder.registerComponent(SocialShare, ShareOnSocialConfig);
Builder.registerComponent(Songkick, SongkickConfig);
Builder.registerComponent(SpotifyActions, SpotifyActionsConfig);
Builder.registerComponent(SpotifyLoginUnlock, SpotifyLoginUnlockConfig);
Builder.registerComponent(SpotifyPlaylistCreator, SpotifyPlaylistCreatorConfig);
Builder.registerComponent(SpotifyStream, SpotifyStreamConfig);
Builder.registerComponent(SpotifyStreamingUnlock, SpotifyStreamingUnlockConfig);
Builder.registerComponent(Stackla, StacklaConfig);
Builder.registerComponent(Text, TextConfig);
Builder.registerComponent(TextRich, TextRichConfig);
Builder.registerComponent(TikTok, TikTokConfig);
Builder.registerComponent(TourDates, TourDatesConfig);
Builder.registerComponent(Translations, TranslationConfig);
Builder.registerComponent(Twitch, TwitchConfig);
Builder.registerComponent(Video, VideoConfig);
Builder.registerComponent(ForeverPresaves, ForeverPresavesConfig);
