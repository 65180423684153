enum API_CONTENT_ROUTE {
  Campaign = 'campaign',
  CampaignVersion = 'public/campaign/version',
  Page = 'page',
  PagesVersion = 'public/pages/version',
};

enum PAGE_PREVIEW_TYPE {
  Draft = 'page-preview/draft/',
  Published = 'page-preview/published/'
};

export {
  API_CONTENT_ROUTE,
  PAGE_PREVIEW_TYPE
};
